import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Zeitplan</>,
        SubHeading: () => (
            <span>
               Hier seht ihr was euch auf unserer Hochzeitsfeier erwarten wird. <br/> Wir freuen uns auf euch!
            </span>
        ),
    },
}
