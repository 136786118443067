import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: [
        {
            Type: () => <>Auto</>,
            Title: () => null,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>Die genauen Details zur Anreise mit dem Auto.</p>
                </>
            ),
            link:
                "https://www.google.at/maps/dir//3382+Schallaburg/@48.1878581,15.3381204,14z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4772656c2b0e5991:0xbacaa4da34ba4981!2m2!1d15.35563!2d48.18786!3e0?hl=de",
        },
        {
            Type: () => <>Öffentlich</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>
                       Die Anreise mit öffentlichen Verkehrsmitteln ist ebenfalls möglich.
                    </p>
                </>
            ),
            link: "https://fahrplan.oebb.at/webapp/?language=de_DE&start=1&_charset=UTF-8&REQ0JourneyProduct_list=0:1111111111011000-000000.&SALL=1&ZALL=1&scotty_advancedSearchMode=0&Z=Schallaburg%20Parkplatz&timeSel=depart&REQ0JourneyDate=27.08.2022&time=11:00#!P|TP!histId|0!histKey|H962597",
        },
    ],
}
