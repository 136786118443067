import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Title: () => <span></span>,
        Date: () => (
            <div className="d-flex flex-column">
                <div>
                    <span style={{}}>
                     
                    </span>
                </div>
                <div>Das Schönste an der Liebe ist ein Leben zu zweit.</div>
            </div>
        ),
        Location: () => <span>Schallaburg, Niederösterreich</span>,
        locationLink: "https://www.google.at/maps/place/3382+Schallaburg/@48.1878591,15.3468752,15z/data=!3m1!4b1!4m5!3m4!1s0x4772656c2b0e5991:0xbacaa4da34ba4981!8m2!3d48.18786!4d15.35563?hl=de",
    }
}
