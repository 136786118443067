import React, { useContext } from "react"
import { useCookies } from "react-cookie"
import { NavLink as RouterNavLink, withRouter } from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import classNames from "classnames"

import { selectLanguage } from "utilities/cookies"
import { signOut } from "actions"
import { Store } from "store"
import { navigation } from "content"

import styles from "./Navigation.module.scss"

function Navigation({ history }) {
    const [cookies, setCookie] = useCookies(["language"])
    const { state, dispatch } = useContext(Store)


    const handleSelectLanguage = lang =>
        setCookie("language", lang, { path: "/" })

    const handleSignOut = () => signOut(dispatch)

    const {
        schedule: ScheduleNavText,
        travel: TravelNavText,
        thingsToDo: ThingsToDoNavText,
        registry: RegistryNavText,
    } = navigation[selectLanguage(cookies)]

    return (
        <Navbar
            expand="lg"
            sticky="top"
            className={styles.navigation}
        >
            <RouterNavLink
                to="/"
                className={classNames("navbar-brand", styles.brand)}
            >
                S & C
            </RouterNavLink>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav>
                    <RouterNavLink
                        to="/schedule"
                        className="nav-link"
                        role="button"
                    >
                        <ScheduleNavText />
                    </RouterNavLink>
                    <RouterNavLink
                        to="/travel"
                        className="nav-link"
                        role="button"
                    >
                        <TravelNavText />
                    </RouterNavLink>
                    <RouterNavLink
                        to="/things-to-do"
                        className="nav-link"
                        role="button"
                    >
                        <ThingsToDoNavText />
                    </RouterNavLink>
                    <RouterNavLink
                        to="/registry"
                        className="nav-link"
                        role="button"
                    >
                        <RegistryNavText />
                    </RouterNavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(Navigation)
