import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Unterkunft</>,
        SubHeading: () => (
            <>
                Es wird eine lange Nacht, denn wir haben viel zu feiern!
                Aus diesem Grund haben wir für euch ein Kontingent vorgemerkt.
                Die Zimmer sind unter dem Namen "Hochzeit von Silvia & Christopher" reserviert.
        
                
            </>
        ),
        link: "https://terresdelebre.travel/en",
    },
}
