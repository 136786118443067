import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Anreise</>,
        SubHeading: () => (
            <>
                Wir heiraten auf der {" "}
                <strong>Schallaburg</strong>. Hier findet ihr Informationen zur Anreise.
            </>
        ),
    },
}
