import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Questions?</>,
        SubHeading: () => (
            <>
                Wenn es Fragen gibt, zögere nicht dich via {" "} bei uns zu melden.
                <a href="mailto:contact@ckremsner.com">contact@ckremsner.com</a>.
            </>
        ),
    },
}
