import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: [
        {
            Title: () => <>Pension Weisses Lamm</>,
            Details: () => (
                <>
                    <ul> 
                        <li>Einzelzimmer inkl. Frühstück € 46,-</li>
                        <li>Doppelzimmer inkl. Frühstück € 70,-</li>
                        <li>Dreibettzimmer inkl. Frühstück € 108,-</li>
                    </ul>
                    <p>Kontingent reserviert bis 8. August 2022</p>
                </>
            ),
            link:
                "http://melk.gv.at/de/Pension_Weisses_Lamm_7",
        },
        {
            Title: () => <>Zum schwarzen Bären</>,
            Details: () => (
                <>
                    <ul> 
                        <li>Doppelzimmer inkl. Frühstück € 180,-</li>
                    </ul>
                    <p>Kontingent reserviert bis 15. Juli 2022</p>
                </>
            ),
            link:
                "https://www.hotelpritz.at/de",
        },
    ],
}
