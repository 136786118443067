import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: [
        {
            Title: () => <>Trauung</>,
            Date: () => null,
            Time: () => "14 Uhr",
            Location: () => <>Garten der Schallaburg</>,
            locationLink:
                "https://www.google.at/maps/place/3382+Schallaburg/@48.1878581,15.3381204,14z/data=!3m1!4b1!4m5!3m4!1s0x4772656c2b0e5991:0xbacaa4da34ba4981!8m2!3d48.18786!4d15.35563?hl=de",
            Address: () => (
                <>
                    <p>Schallaburg 1</p>
                    <p>3382 Schallaburg</p>
                </>
            ),
            mapLink:
                "https://www.google.at/maps/place/3382+Schallaburg/@48.1878581,15.3381204,14z/data=!3m1!4b1!4m5!3m4!1s0x4772656c2b0e5991:0xbacaa4da34ba4981!8m2!3d48.18786!4d15.35563?hl=de",
            Details: () => (
                <>
                    <p>
                        
                    </p>
                </>
            ),
        },
    ],
}
