import React from "react"
import Emoji from "react-emoji-render"
import Octicon, { MarkGithub } from "@primer/octicons-react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Content: () => (
            <>
                <p>
                    Kontaktiere uns via{" "}
                    <a href="mailto:contact@ckremsner.com">
                        contact@ckremsner.com
                    </a>
                    .
                </p>
            </>
        ),
    },
}
