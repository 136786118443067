import React from "react"

import { LANGUAGE } from "actions/constants"
import { Heart } from "photos/heart-svgrepo-com.svg"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Geschenke</>,
        SubHeading: () => (
            <>
                <span>
                    Eure Anwesenheit an unserem großen Tag ist für uns das Schönste. <br/>
                    Falls ihr uns jedoch etwas schenken möchtet, würden wir uns über einen
                    Zuschuss für unser neues Zuhause freuen.
                </span>
                <div style={{marginTop: 80}}>
                    <iframe src="https://giphy.com/embed/8Og4UuBGsVhEEoSO0L" width="240" height="240" frameBorder="0" class="giphy-embed"></iframe><p></p>
                </div>
            </>
        ),
    },
}
